import React from "react";
import { UserContext } from "../context/User";

const Sidebar = ({ page, setPage }) => {
  const { user, signOut } = React.useContext(UserContext);

  const menuItem =
    "text-md m-0 text-slate-700 cursor-pointer hover:text-black py-3 pl-7";

  const menuHeader = "font-bold text-slate-800 text-lg m-0";
  return (
    <div className="flex flex-col justify-between h-full bg-white p-6">
      <div>
        <div className="pb-12">
          <div className="avatar bg-primary text-white">
            {user.first_name ? user.first_name.charAt(0) : null}
          </div>
          <p className="font-bold text-xl m-0 p-0">
            {`${user.first_name ? user.first_name : null} ${
              user.last_name ? user.last_name : null
            }`}
          </p>
          <p className="m-0">{user.email}</p>
        </div>
        <p onClick={() => setPage()} className={`${menuHeader}`}>
          <i className="fa-sharp fa-solid fa-user pr-2"></i> My Account
        </p>
        <p onClick={() => setPage("overview")} className={`${menuItem} pb-10`}>
          Dashboard
        </p>

        <p className={`${menuHeader}`}>
          <i className="fa-solid fa-sparkles pr-2"></i> Blizzard Rewards
        </p>
        <p onClick={() => setPage("rewards")} className={`${menuItem} pb-10`}>
          Status
        </p>

        <p onClick={() => setPage()} className={`${menuHeader}`}>
          <i className="fa-regular fa-receipt pr-2"></i> Orders
        </p>
        <p onClick={() => setPage("orders")} className={`${menuItem}`}>
          Past Orders
        </p>
        {/* <p onClick={() => setPage("payment")} className={`${menuItem}`}>
          Payment Methods
        </p> */}
        <p
          onClick={() => setPage("addresses")}
          className={`${menuItem}  pb-10`}
        >
          Address Book
        </p>

        <p className={`${menuHeader}`}>
          <i className="fa-solid fa-sitemap pr-2"></i> Sites
        </p>

        <p
          onClick={() => window.open("https://www.blizzardpro.com")}
          className={`${menuItem}`}
        >
          Blizzard Pro
        </p>
        <p
          onClick={() =>
            //open in new tab

            window.open("https://www.blizzardmixtape.com")
          }
          className={`${menuItem}`}
        >
          Blizzard Mixtape
        </p>
        <p
          onClick={() => window.open("https://www.irisprovideo.com")}
          className={`${menuItem}`}
        >
          IRiS LED Video
        </p>
      </div>

      <p onClick={() => signOut()} className={`${menuItem}`}>
        <i className="fa-solid fa-arrow-right-from-bracket"></i> Sign Out
      </p>
    </div>
  );
};

export default Sidebar;
