import axios from "axios";
import { crewBadge, headlinerBadge, legendBadge } from "./data/rewards";

export const API_URL = "https://api.blizzard.lighting";
export const DEV_API_URL = "https://api.dev3.io";
export const makeAxiosCall = (method, endpoint, data, config) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios({
        method: method,
        url: DEV_API_URL + endpoint,
        data: data,
        timeout: 10000,
        withCredentials: true,
        ...config,
      });

      if (response) {
        resolve(response);
      } else {
        reject(response);
      }
    } catch (error) {
      // console.error(`CAUGHT ERROR IN AXIOS ${method}`);
      // console.error(error.response ? error.response : error);
      reject(error);
    }
  });
};

export function formatMoney(amount) {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }).format(amount);
}

export function addToCart(product) {
  // Get the existing cart data from local storage or initialize an empty array
  let cart = JSON.parse(localStorage.getItem("cart")) || [];

  // Check if the product already exists in the cart
  const existingProduct = cart.find((item) => item.slug === product.slug);

  if (existingProduct) {
    // If the product already exists, increase the quantity
    existingProduct.quantity += 1;
  } else {
    // If the product doesn't exist, create a new entry
    cart.push(product);
  }

  // Update the cart data in local storage
  localStorage.setItem("cart", JSON.stringify(cart));
  window.dispatchEvent(new Event("storage"));
}

//convert time to 12 hour format
export function convertTime(time) {
  //timestamp to mm/dd/yy hh:mm
  let date = new Date(time);
  let hours = date.getHours();
  let minutes = date.getMinutes();
  let ampm = hours >= 12 ? "p" : "a";
  let month = date.getMonth() + 1;
  let day = date.getDate();
  let year = date.getFullYear();
  year = year.toString().substr(-2);

  hours = hours % 12;
  hours = hours ? hours : 12; // the hour "0" should be "12"
  minutes = minutes < 10 ? `0${minutes}` : minutes;
  let strTime = `${month}/${day}/${year} ${hours}:${minutes}${ampm}`;
  return strTime;
}

export function calculateTotal(orders, format) {
  let total = 0;
  orders.forEach((item) => {
    total += item.total;
  });
  if (format) {
    return formatMoney(total);
  }

  return total;
}

export function tierCalc(orders) {
  let totalSpent = calculateTotal(orders);

  switch (totalSpent) {
    case totalSpent < 1000:
      return 0;
    case totalSpent < 5000:
      return 1;
    case totalSpent >= 5000:
      return 2;
    default:
      return 0;
  }
}

export function getTierBadge(int) {
  switch (int) {
    case 0:
      return crewBadge;
    case 1:
      return headlinerBadge;
    case 2:
      return legendBadge;
    default:
      return crewBadge;
  }
}

export function calculateRewardsExp() {
  const now = new Date();
  const currentMonth = now.getMonth();
  const currentYear = now.getFullYear();
  let endMonth, endDay;

  if (currentMonth >= 0 && currentMonth <= 2) {
    endMonth = 2;
    endDay = 31;
  } else if (currentMonth >= 3 && currentMonth <= 5) {
    endMonth = 5;
    endDay = 30;
  } else if (currentMonth >= 6 && currentMonth <= 8) {
    endMonth = 8;
    endDay = 30;
  } else {
    endMonth = 11;
    endDay = 31;
  }

  const endDate = new Date(currentYear, endMonth, endDay);
  const daysLeft = Math.ceil((endDate - now) / (1000 * 60 * 60 * 24));

  return daysLeft;
}
